<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                v-model="ex11"
                label="red"
                color="red"
                value="red"
                hide-details
              ></v-switch>
              <v-switch
                v-model="ex11"
                label="red darken-3"
                color="red darken-3"
                value="red darken-3"
                hide-details
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                v-model="ex11"
                label="indigo"
                color="indigo"
                value="indigo"
                hide-details
              ></v-switch>
              <v-switch
                v-model="ex11"
                label="indigo darken-3"
                color="indigo darken-3"
                value="indigo darken-3"
                hide-details
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                v-model="ex11"
                label="orange"
                color="orange"
                value="orange"
                hide-details
              ></v-switch>
              <v-switch
                v-model="ex11"
                label="orange darken-3"
                color="orange darken-3"
                value="orange darken-3"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>

          <v-row class="mt-12">
            <v-col cols="12" sm="4" md="4">
              <v-switch
                v-model="ex11"
                label="primary"
                color="primary"
                value="primary"
                hide-details
              ></v-switch>
              <v-switch
                v-model="ex11"
                label="secondary"
                color="secondary"
                value="secondary"
                hide-details
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                v-model="ex11"
                label="success"
                color="success"
                value="success"
                hide-details
              ></v-switch>
              <v-switch
                v-model="ex11"
                label="info"
                color="info"
                value="info"
                hide-details
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-switch
                v-model="ex11"
                label="warning"
                color="warning"
                value="warning"
                hide-details
              ></v-switch>
              <v-switch
                v-model="ex11"
                label="error"
                color="error"
                value="error"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
export default {
  data() {
    return {
      permissions: [],
      form: {
        code: null,
        id: null,
      },
      switcha: true,
      ex11: [
        "red",
        "indigo",
        "orange",
        "primary",
        "secondary",
        "success",
        "info",
        "warning",
        "error",
        "red darken-3",
        "indigo darken-3",
        "orange darken-3",
      ],
    };
  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Roles & Permissions" }]);
  },
  methods: {
    getPermissions() {
      ApiService.get("permission")
        .then(({ data }) => {
          this.permissions = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
